import React from "react";
import { useEffect } from "react";
import AOS from "aos";
import "aos/dist/aos.css";
import ProjectImage1 from "../../images/projecten/isytech_project1.JPEG";
import ProjectImage2 from "../../images/projecten/isytech_project2.jpg";
import ProjectImage3 from "../../images/projecten/isytech_project3.jpg";
import ProjectImage4 from "../../images/projecten/isytech_project4.JPG";
import ProjectImage5 from "../../images/projecten/isytech_project5.jpg";
import { useTranslation } from "react-i18next";

const ProjectCard = ({ image: Image, title, content, extraImg: Image2 }) => (
  <section data-aos="fade-up" className="project-card">
    <img src={Image} alt={title} />
    <div className="project-content">
      <h2>{title}</h2>
      {typeof content === "string" ? <p>{content}</p> : content}
    </div>
  </section>
);

const Project = () => {
  const { t } = useTranslation();

  useEffect(() => {
    AOS.init({ duration: 500 });
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="about">
      <div div className="about-upper">
        <div className="about-information">
          <div className="small-title">{t("projectsSmall")}</div>
          <div className="page-title">{t("projectsTitle")}</div>
          <div className="page-desc">{t("projectsText")}</div>
        </div>
      </div>
      <div className="about-content">
        <ProjectCard
          image={ProjectImage1}
          title={t("project1Title")}
          content={t("project1Text")}
        />
        <ProjectCard
          image={ProjectImage2}
          title={t("project2Title")}
          content={t("project2Text")}
        />
        <ProjectCard
          image={ProjectImage3}
          title={t("project3Title")}
          content={t("project3Text")}
        />
        <ProjectCard
          image={ProjectImage4}
          title={t("project4Title")}
          content={t("project4Text")}
        />
        <ProjectCard
          image={ProjectImage5}
          title={t("project5Title")}
          content={t("project5Text")}
        />
      </div>
    </div>
  );
};

export default Project;
